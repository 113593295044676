import React, { useEffect, useState } from 'react';
import './Channels.css';

function getTypeLabel(type) {
    switch (type) {
        case 0:
            return 'Text';
        case 1:
            return 'Voice';
        case 2:
            return 'Forum';
        default:
            return 'Unknown';
    }
}

function Channels() {
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Запрашиваем данные с сервера
        fetch('https://p5.x5dfg.xyz:443/c/1')
            .then(response => response.json())
            .then(data => {
                // Установим состояние channels прямо из полученных данных
                setChannels(data); // Предполагается, что data - это массив
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching channels:', error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            {channels.map(channel => (
                <div 
                    className="channel" 
                    key={channel.id} 
                    data-id={channel.id}
                >
                    <img 
                        src={channel.avatar || './error.png'} 
                        alt={channel.name} 
                        title={channel.name} 
                        className="channel-icon" 
                    />
                    <span className="channel-name">{channel.name}</span>
                    <span title={"Types:\n0-Text\n1-Voice\n2-Forum\nOther-Unknow"} className="channel-type">{getTypeLabel(channel.type)}</span>
                </div>
            ))}
        </>
    );
}

export default Channels;
