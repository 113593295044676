import React, { useEffect } from 'react';

const PsutilViewer = () => {
  useEffect(() => {
    // Создаем WebSocket соединение
    const socket = new WebSocket('wss://p5.x5dfg.xyz:443/ws/psutil');

    // Обработчик для события открытия соединения
    socket.onopen = () => {
      console.log('Соединение установлено');
    };

    // Обработчик для получения сообщений
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Данные о состоянии сервера:');
      console.log(`Диск: ${data.disk_percent}%`);
      console.log(`CPU: ${data.cpu_percent}%`);
      console.log(`Оперативная память: ${data.memory_percent}%`);
    };

    // Обработчик для события закрытия соединения
    socket.onclose = () => {
      console.log('Соединение закрыто');
    };

    // Обработчик для ошибок
    socket.onerror = (error) => {
      console.error('Ошибка WebSocket:', error);
    };

    // Очистка при размонтировании компонента
    return () => {
      socket.close();
    };
  }, []); // Пустой массив зависимостей для вызова один раз при монтировании

  return (
    <>
      <p>Данные о состоянии сервера в консоли</p>
    </>
  );
};

export default PsutilViewer;
