import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import Members from '../components/members/Members';
import Channels from '../components/channels/Channels';
import VoiceChannel from './VoiceChannel';
import ScreenCapture from './ScreenCapture';

import ThemeToggleButton from '../components/themetogglebutton/ThemeToggleButton';
import { ReactComponent as File } from '../assets/svg/file.svg';
import { ReactComponent as Send } from '../assets/svg/send.svg';

import PsutilViewer from './s';

const ChatName = 'Название чата';
const tempAvatar = 'https://p5.x5dfg.xyz/static/favicons/icon.png';

function convertTimestamps() {
    const timestamps = document.querySelectorAll('.timestamp:not(.processed)');
    timestamps.forEach((timestamp) => {
        let textContent = timestamp.textContent.trim();
        let unixTime = parseFloat(textContent);

        if (!isNaN(unixTime) && unixTime > 0 && unixTime.toString().length > 8) {
            const date = new Date(unixTime * 1000);
            const formattedTime = date.toLocaleTimeString([], {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            });

            timestamp.textContent = formattedTime;
            timestamp.classList.add('processed');
        } else {
            console.error('Invalid UNIX timestamp or too short');
        }
    });
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function getTypeLabel(type) {
    const typeLabels = {
        0: 'User',
        1: 'Bot',
        2: 'P5.Mod',
        3: 'P5.Staff',
        4: 'P5.System',
    };
    return typeLabels[type] || 'Unknown';
}

function sendMessage(wsRef, chatInputRef, setMessages, setMessageCount, base64Images, setBase64Images, setFilePreviewUrls) {
    if (wsRef.current.readyState === WebSocket.OPEN) {
        const content = chatInputRef.current.value;
        if (content.trim() === '' && base64Images.length === 0) return;

        const msg = {
            content: content,
            user: {
                nick: "x5dfg",
                username: "@x5dfg",
                type: 3,
                avatar: tempAvatar
            },
            timestamp: Math.floor(new Date().getTime() / 1000),
            id: Math.floor(new Date().getTime() / 1000), // Используйте уникальный идентификатор
            client: window.location.protocol === 'https:' ? 'prod' : 'dev',
            images: base64Images
        };

        wsRef.current.send(JSON.stringify(msg));

        fetch('https://p5.x5dfg.xyz/notice', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ notices: [msg] })
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => {
                    throw new Error(`Network response was not ok: ${JSON.stringify(err)}`);
                });
            }
            return response.json();
        })
        .then(data => {
            console.log('Notification sent successfully:', data);
        })
        .catch(error => {
            console.error('Error sending notification:', error);
        });

        chatInputRef.current.value = '';
        setBase64Images([]);
        setFilePreviewUrls([]);
    } else {
        console.error('WebSocket is not open. Ready state: ', wsRef.current.readyState);
    }
}

function handleFileButtonClick(fileInputRef) {
    fileInputRef.current.click();
}

function handleFileChange(event, setFilePreviewUrls, setBase64Images) {
    const files = Array.from(event.target.files);
    if (files.length > 4) {
        alert('Вы можете выбрать только до 4 файлов.');
        return;
    }

    const promises = files.map(file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (e) => {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    });

    Promise.all(promises)
        .then(base64Results => {
            setBase64Images(base64Results);
            setFilePreviewUrls(files.map(file => URL.createObjectURL(file)));
        })
        .catch(error => {
            console.error('Ошибка при преобразовании файлов в base64:', error);
        });
}

function handleDelete(messageId, setMessages, setMessageCount) {
    fetch(`https://p5.x5dfg.xyz:443/messages/${messageId}`, {
        method: 'DELETE',
    })
        .then(response => {
            if (!response.ok) {
                throw new Error("Failed to delete message");
            }
            setMessages(prevMessages => {
                const updatedMessages = prevMessages.filter(msg => msg.id !== messageId);
                setMessageCount(updatedMessages.length);
                return updatedMessages;
            });
        })
        .catch(error => {
            console.error("Error deleting message:", error);
        });
}

function App() {
    const chatContentRef = useRef(null);
    const fileInputRef = useRef(null);
    const [filePreviewUrls, setFilePreviewUrls] = useState([]);
    const [messages, setMessages] = useState([]);
    const [messageCount, setMessageCount] = useState(0);
    const chatInputRef = useRef(null);
    const wsRef = useRef(null);
    const [base64Images, setBase64Images] = useState([]);

    useEffect(() => {
        convertTimestamps();

        wsRef.current = new WebSocket('wss://p5.x5dfg.xyz:443/ws/messages');

        wsRef.current.addEventListener('open', () => {
            console.info('WebSocket connection opened');
        });

        wsRef.current.addEventListener('message', (event) => {
            try {
                const data = JSON.parse(event.data);
                if (Array.isArray(data)) {
                    setMessages(prevMessages => {
                        const newMessages = [...prevMessages, ...data];
                        setMessageCount(newMessages.length);
                        return newMessages;
                    });
                } else if (typeof data === 'object') {
                    setMessages(prevMessages => {
                        const newMessages = [...prevMessages, data];
                        setMessageCount(newMessages.length);
                        if (data.id) {
                            // Обновляем URL с новым id
                            window.location.hash = data.id;
                            // Прокручиваем к новому сообщению
                            setTimeout(() => {
                                const element = document.getElementById(data.id);
                                if (element) {
                                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                }
                            }, 100); // Задержка для обеспечения корректной прокрутки
                        }
                        return newMessages;
                    });
                } else {
                    console.error('Received data is not an array or object:', data);
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        });

        wsRef.current.addEventListener('close', () => {
            console.warn('WebSocket connection closed');
        });

        wsRef.current.addEventListener('error', (event) => {
            console.error(`WebSocket error: ${event.message || event}`);
        });

        return () => {
            wsRef.current.close();
        };
    }, []);

    useEffect(() => {
        return () => {
            filePreviewUrls.forEach(url => URL.revokeObjectURL(url));
        };
    }, [filePreviewUrls]);

    return (
        <div className="flex-container">
            <aside className="sidebar">
                <div className="panel">
                    <Channels/>
                    <ScreenCapture/>
                    <VoiceChannel/>
                    <PsutilViewer/>
                </div>
            </aside>
            <main className="flex-chat-container">
                <header className="chat-name">
                    <h2>{ChatName}</h2>
                    <ThemeToggleButton/>
                    <p id="msgCount">Сообщений - {messageCount}</p>
                </header>
                <section className="chat-content-container" ref={chatContentRef}>
                    <div className="chat-content">
                        {messages.map((msg, index) => (
                            <div id={msg.id} data-id={msg.id} key={index} className="chat-message">
                                {msg.user && (
                                    <img
                                        className="avatar"
                                        draggable="false"
                                        src={msg.user.avatar}
                                        title={msg.user.username}
                                        alt="Avatar"
                                    />
                                )}
                                <div className="message-content">
                                    <div className="message-header">
                                        <span className="username">
                                            {msg.user ? `${msg.user.nick} / ${getTypeLabel(msg.user.type)}` : 'Unknown user'}
                                        </span>
                                        <span className="timestamp">{new Date(msg.timestamp * 1000).toLocaleString()}</span>
                                        <button className="delete-button" onClick={() => handleDelete(msg.id, setMessages, setMessageCount)}>
                                            Удалить
                                        </button>
                                        <span className="client-label" style={{ marginLeft: '8px', fontSize: '0.9em', color: '#666' }}>
                                            {msg.client === 'prod' ? 'Client: Production' : 'Client: Dev-Core'}
                                        </span>
                                    </div>
                                    <div className="message-text">
                                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                                    </div>
                                    {msg.images && msg.images.length > 0 && (
                                        <div className="message-images">
                                            {msg.images.map((image, idx) => (
                                                <img key={idx} src={image} alt={`Image ${idx}`} className="message-image" />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <footer className="chat-input">
                    <div className="cont">
                        <button id="file-label" onClick={() => handleFileButtonClick(fileInputRef)}>
                            <File />
                        </button>
                        <textarea
                            id="chat-input"
                            placeholder="Сообщение"
                            rows="1"
                            maxLength="2048"
                            ref={chatInputRef}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                    event.preventDefault();
                                    sendMessage(wsRef, chatInputRef, setMessages, setMessageCount, base64Images, setBase64Images, setFilePreviewUrls);
                                }
                            }}
                        />
                        <button id="send-button" onClick={() => sendMessage(wsRef, chatInputRef, setMessages, setMessageCount, base64Images, setBase64Images, setFilePreviewUrls)}>
                            <Send />
                        </button>
                    </div>
                    <input
                        type="file"
                        multiple
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(event) => handleFileChange(event, setFilePreviewUrls, setBase64Images)}
                    />
                </footer>
                <div className="file-previews">
                    {filePreviewUrls.map((url, idx) => (
                        <img key={idx} src={url} alt={`Preview ${idx}`} className="file-preview-image" />
                    ))}
                </div>
            </main>
            <aside className="sidebar">
                <div className="panel">
                    <Members/>
                </div>
            </aside>
        </div>
    );
}

export default App;
