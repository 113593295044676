import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Check.css'; // Импорт CSS

function Check() {
  const [backendStatus, setBackendStatus] = useState(null);
  const [dots, setDots] = useState('');
  const [log, setLog] = useState('Проверка доступности серверов');
  const navigate = useNavigate();

  const checkBackend = async () => {
    const timeoutPromise = new Promise((_, reject) => 
      setTimeout(() => reject(new Error('Таймаут при подключении к бэкенду')), 7000)
    );

    try {
      const response = await Promise.race([
        fetch('https://p5.x5dfg.xyz:443/ping', { method: 'HEAD' }),
        timeoutPromise
      ]);

      if (response.ok) {
        setBackendStatus(true);
        setLog('Бэкенд доступен. Перенаправление...');
        setTimeout(() => navigate('/chat'), 1000);
      } else {
        throw new Error('Ошибка на стороне сервера');
      }
    } catch (error) {
      setBackendStatus(false);
      setLog(error.message === 'Таймаут при подключении к бэкенду'
        ? 'Не удалось подключиться к нашим серверам (таймаут).'
        : 'Не удалось подключиться к нашим серверам.'
      );
    }
  };

  useEffect(() => {
    checkBackend();

    const interval = setInterval(() => {
      setDots(prev => (prev.length === 3 ? '' : prev + '.'));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      <img
        src={backendStatus === false ? './error.png' : './logo512.png'}
        alt="Логотип"
        className={`logo ${backendStatus === false ? '' : 'animated'}`} // Условное присвоение классов
      />
      <p className="checking-text">
        {backendStatus === false ? 'Ууупс...' : `Проверка доступности${dots}`}
      </p>
      <p className="log" style={{ color: backendStatus === false ? '#ff7300' : 'inherit' }}>
        {log}
      </p>
    </div>
  );
}

export default Check;
