import React, { useState, useRef } from 'react';

// WAV Encoder для преобразования аудиоданных
const encodeWAV = (samples, sampleRate) => {
  const buffer = new ArrayBuffer(44 + samples.length * 2);
  const view = new DataView(buffer);

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  /* RIFF identifier */
  writeString(view, 0, 'RIFF');
  /* file length */
  view.setUint32(4, 36 + samples.length * 2, true);
  /* RIFF type */
  writeString(view, 8, 'WAVE');
  /* format chunk identifier */
  writeString(view, 12, 'fmt ');
  /* format chunk length */
  view.setUint32(16, 16, true);
  /* sample format (raw) */
  view.setUint16(20, 1, true);
  /* channel count */
  view.setUint16(22, 1, true); // 1 для моно
  /* sample rate */
  view.setUint32(24, sampleRate, true);
  /* byte rate (sample rate * block align) */
  view.setUint32(28, sampleRate * 2, true); // 2 для 16 бит
  /* block align (channel count * bytes per sample) */
  view.setUint16(32, 2, true);
  /* bits per sample */
  view.setUint16(34, 16, true);
  /* data chunk identifier */
  writeString(view, 36, 'data');
  /* data chunk length */
  view.setUint32(40, samples.length * 2, true);

  // Write the PCM samples
  let offset = 44;
  for (let i = 0; i < samples.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, samples[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
  }

  return buffer;
};

const VoiceChannel = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [wsStatus, setWsStatus] = useState('Отключён');
  const ws = useRef(null);
  const audioContext = useRef(null);
  const mediaStream = useRef(null); // Ссылка на медиа поток

  const checkWebSocketConnection = (url) => {
    return new Promise((resolve) => {
      const wsTest = new WebSocket(url);
      wsTest.onopen = () => {
        resolve(true);
        wsTest.close();
      };
      wsTest.onerror = () => {
        resolve(false);
      };
    });
  };

  const toggleVoiceChannel = async () => {
    const wsUrl = 'wss://p5.x5dfg.xyz:443/ws/voice/1';
    const isWsAvailable = await checkWebSocketConnection(wsUrl);

    if (!isWsAvailable) {
      setWsStatus('WebSocket недоступен');
      return;
    }

    if (!isConnected) {
      setWsStatus('Подключён');
      ws.current = new WebSocket(wsUrl);

      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();

      ws.current.onmessage = async (event) => {
        console.log('Получены данные от сервера:', event.data);
        const arrayBuffer = await event.data.arrayBuffer();
        audioContext.current.decodeAudioData(arrayBuffer, (decodedData) => {
          const source = audioContext.current.createBufferSource();
          source.buffer = decodedData;
          source.connect(audioContext.current.destination);
          source.start();
        }, (error) => {
          console.error('Ошибка при декодировании аудиоданных:', error);
        });
        
      };

      mediaStream.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaStreamSource = audioContext.current.createMediaStreamSource(mediaStream.current);
      const processor = audioContext.current.createScriptProcessor(8192, 1, 1); // Увеличен размер буфера

      processor.onaudioprocess = (event) => {
        const inputBuffer = event.inputBuffer.getChannelData(0);
        const wavData = encodeWAV(inputBuffer, audioContext.current.sampleRate);

        if (ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(wavData); // Отправляем данные на сервер в формате WAV
        }
      };

      mediaStreamSource.connect(processor);
      processor.connect(audioContext.current.destination);
      setIsConnected(true);
    } else {
      if (ws.current) {
        ws.current.close();
      }
      mediaStream.current.getTracks().forEach(track => track.stop()); // Останавливаем микрофон
      setWsStatus('Отключён');
      setIsConnected(false);
    }
  };

  return (
    <>
      <div>Статус: {wsStatus}</div>
      <button onClick={toggleVoiceChannel}>
        {isConnected ? 'Откл' : 'Вкл'}
      </button>
    </>
  );
};

export default VoiceChannel;
