import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import './newcss/root.css';
import Chat from './chat/Chat';
import Check from './check/CheckBackend';

function RedirectToCheck() {
  const location = useLocation();
  const targetPath = location.pathname === '/chat' ? '/chat' : location.pathname; // Если корневой путь, устанавливаем по умолчанию /chat

  return <Navigate to={`/check?redirect=${targetPath}`} />;
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/chat" element={<Chat />} />
        <Route path="/check" element={<Check />} />
        <Route path="*" element={<RedirectToCheck />} /> {/* Редирект для всех неизвестных маршрутов */}
      </Routes>
    </Router>
  </React.StrictMode>
);
