import React, { useRef, useState } from 'react';

const ScreenCapture = () => {
    const videoRef = useRef(null);
    const [captureState, setCaptureState] = useState({
        isCapturing: false,
        mediaStream: null,
        mediaRecorder: null,
        recordedChunks: [],
    });

    async function startCapture() {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true,
            });

            videoRef.current.srcObject = stream;

            const chunks = [];
            const recorder = new MediaRecorder(stream, { mimeType: 'video/mp4' });

            recorder.ondataavailable = function (event) {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                }
            };

            recorder.onstop = function () {
                handleStopRecording(chunks);
            };

            recorder.start();

            setCaptureState((prev) => ({
                ...prev,
                isCapturing: true,
                mediaStream: stream,
                mediaRecorder: recorder,
                recordedChunks: chunks,
            }));

            stream.getTracks().forEach(function (track) {
                track.onended = stopCapture;
            });
        } catch (err) {
            console.error('Ошибка при захвате экрана: ', err);
        }
    }

    function handleStopRecording(chunks) {
        if (chunks.length > 0) {
            const blob = new Blob(chunks, { type: 'video/mp4' });
            const url = URL.createObjectURL(blob);
            const now = new Date();
            const dateString = now.toISOString().replace(/T/, '_').replace(/:/g, '-').split('.')[0];
            const filename = `screen-capture_${dateString}.mp4`;

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } else {
            console.log('Нет данных для сохранения');
        }

        setCaptureState((prev) => ({ ...prev, recordedChunks: [] }));
        console.log('Запись остановлена');
    }

    function stopCapture() {
        const { mediaStream, mediaRecorder } = captureState;
        if (mediaStream) {
            mediaStream.getTracks().forEach(function (track) {
                track.stop();
            });
            videoRef.current.srcObject = null;
            if (mediaRecorder) {
                mediaRecorder.stop();
            }

            setCaptureState({
                isCapturing: false,
                mediaStream: null,
                mediaRecorder: null,
                recordedChunks: [],
            });
        }
    }

    return (
        <>
            <button onClick={captureState.isCapturing ? stopCapture : startCapture}>
                {captureState.isCapturing ? 'Стоп' : 'Запись'}
            </button>
            <video ref={videoRef} autoPlay style={{ border: '1px solid black', width: '100%' }} />
        </>
    );
};

export default ScreenCapture;
