import React, { useState, useEffect } from 'react';
import { ReactComponent as Light } from './light.svg';
import { ReactComponent as Dark } from './dark.svg';


function ThemeToggleButton() {
    const [isLightTheme, setIsLightTheme] = useState(false);

    useEffect(() => {
        // Устанавливаем тему в зависимости от сохраненных предпочтений пользователя
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'light') {
            setIsLightTheme(true);
            document.body.classList.add('light-theme');
        } else {
            document.body.classList.remove('light-theme');
        }
    }, []);

    function toggleTheme() {
        setIsLightTheme(prevTheme => !prevTheme);
        if (isLightTheme) {
            document.body.classList.remove('light-theme');
            localStorage.setItem('theme', 'dark');
        } else {
            document.body.classList.add('light-theme');
            localStorage.setItem('theme', 'light');
        }
    };

    return (
        <button style={{color: 'white', padding: '10px 20px', cursor: 'pointer'}} onClick={toggleTheme} title='Выбранная вами тема сохраняется в кеш браузера'>
            {isLightTheme ? <Light/> : <Dark/>}
        </button>
    );
};

export default ThemeToggleButton;
